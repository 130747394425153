import {
  resolveRealPath,
  useManagerFormValue,
  useCurrentIdPathMap,
  type INode,
  type DependentOn,
} from '@manager'
import { Parser } from 'expr-eval'
import { parseValue } from '@manager/utils/diffManagerForm/utils'
import { getExpressions, getObjectNodePaths, getPaths } from './utils'

export const useDependentOn = (node: INode, parentPath: string | undefined) => {
  const idPathMap = useCurrentIdPathMap()
  const { getProperty } = useManagerFormValue()

  const evaluate = (dependentOn: DependentOn[] | null | undefined) => {
    let paths = getPaths(idPathMap, dependentOn)

    // The `objectNodePath` will only be defined if the path points to
    // a node of kind OBJECT. We resolve the path to the array,
    // so we can evaluate the array length later.
    let objectNodePaths = getObjectNodePaths(paths)

    // It's always visible if it doesn't depend on anything
    if (!paths || paths.length === 0) return true

    const parser = new Parser()
    const expressions = getExpressions(dependentOn, parser)

    // TODO: Report to Sentry
    // Resolve the indexes of the arrays in the path if any
    if (parentPath) {
      try {
        paths = paths.map((path) => resolveRealPath(path, parentPath))
        objectNodePaths = objectNodePaths
          ? objectNodePaths.map((objectNodePath) =>
              resolveRealPath(objectNodePath, parentPath),
            )
          : undefined
      } catch (error) {
        console.error(error)
      }
    }

    const evaluate = (
      index: number,
      value: unknown,
      objectNodeValue?: unknown[],
    ) => {
      try {
        const evaluated = expressions[index]?.evaluate({
          $value: value,
          $node: node,
          $length: objectNodeValue?.length, // only available for nodes of kind OBJECT
          $isDefined: (value) => !!parseValue(value),
          $hasSomeValue: (array: any, key, value) =>
            Array.isArray(array) &&
            array.some((item) => {
              return (key as string) in item && item[key as string] === value
            }),
        })
        return evaluated ?? false
      } catch (e) {
        return false
      }
    }

    return paths
      .map((path, index) => {
        const value = getProperty(path!)
        const objectNodeValue = (
          objectNodePaths?.[index]
            ? getProperty(objectNodePaths[index])
            : undefined
        ) as unknown[]
        return evaluate(index, value, objectNodeValue) as boolean
      })
      .every((value) => !!value)
  }

  return { evaluate }
}
