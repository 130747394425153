import type { DependentOn } from '@manager'
import type { Parser } from 'expr-eval'

export function removeLastArrayAccess(str: string) {
  const matches = [...str.matchAll(/\[\d*]$/g)]
  if (matches.length > 0) {
    // Get the last match
    const lastMatch = matches[matches.length - 1]
    // Remove the last match
    return (
      str.substring(0, lastMatch.index) +
      str.substring(lastMatch.index + lastMatch[0].length)
    )
  }
  // No matches found, return the original string
  return str
}

export function getPaths(
  idPathMap: Map<number, string>,
  dependentOn: DependentOn[] | null,
) {
  return dependentOn
    ? dependentOn
        .map(({ id }) => idPathMap.get(id))
        .filter((path): path is string => Boolean(path))
    : undefined
}

export function getObjectNodePaths(paths: string[] | undefined) {
  return paths ? paths.map((path) => removeLastArrayAccess(path)) : undefined
}

export function getExpressions(
  dependentOn: DependentOn[] | null,
  parser: Parser,
) {
  return dependentOn!.map(({ condition }) => {
    try {
      return parser.parse(condition ?? '$isDefined($value)')
    } catch (e) {
      console.error(e)
      return null
    }
  })
}
